import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.android} codeUrl={checklists.componentAlert.codeUrl.android} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`The Alert dialog component is utilized to show an alert prompt in a dialog. It will show alert in native dialog for the platform.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "alt": "Alert dialog legion react native",
            "height": 600,
            "resize-mode": "contain",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alertDialog/react-native/react-native-alert-demo.gif",
            "width": "auto"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { AlertDialog } from "@legion-crossplatform/ui";


const Alert = () => {
  return (
    <AlertDialog 
      triggerLabel="Show Alert"
      title="Alert"
      description="By pressing yes you agree to the terms and conditions",
      confirmText="Accept"
      cancelText="Cancel"
      onPressConfirm={() => console.log("Confirm")}
    />
  )
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cancelText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set text for cancel button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`confirmText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set text for confirm button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set text for description in alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onPressConfirm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set action when pressing confirm button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the title of alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`triggerLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`String`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the button text of alert`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      